/**
 * Format path to show parent directory
 * @param {String} path - Full path
 * @returns {String} - Formatted path
 */
export function formatPath(path) {
  if (!path) {
    return '';
  }
  
  // Get the path parts
  const parts = path.split('/');
  
  // Remove the last part (file/folder name)
  parts.pop();
  
  if (parts.length === 0) {
    return '';
  }
  
  // For the format shown in the screenshot:
  // "cyberpunk 2" or "cyberpunk 2/level 3"
  return parts.join('/');
}
