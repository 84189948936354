<template>
  <v-expansion-panel v-if="hasFiles" class="upload-file-details">
    <v-expansion-panel-content>
      <template v-slot:header>
        <v-layout row align-center justify-space-between class="upload-file-details__header">
          <div class="d-flex align-center">
            <w-icon small mini class="mr-2 upload-file-details__icon">fas fa-folder-open</w-icon>
            <span class="upload-file-details__title">{{ $t('documents.upload.file_details') }} ({{ files.length }})</span>
          </div>
        </v-layout>
      </template>
      <v-card>
        <v-card-text class="pa-0">
          <template v-if="files.length > 20">
            <RecycleScroller
              v-slot:default="{ item: file }"
              :buffer="400"
              class="upload-file-details__list pa-0"
              :item-size="46"
              :items="files"
              key-field="id"
            >
              <UploadFileListItem :value="file" />
            </RecycleScroller>
          </template>
          <v-list v-else>
            <UploadFileListItem v-for="file in files" :key="file.id" :value="file" />
          </v-list>
        </v-card-text>
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { formatPath } from '@/components/Documents/Upload/utils'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

export default {
  name: 'UploadFileDetails',
  components: {
    UploadFileListItem: () => ({
      component: import('@/components/Documents/Upload/UploadFileListItem')
    })
  },
  props: {
    batchId: {
      default: null,
      type: String
    },
    files: {
      default: () => ([]),
      type: Array
    }
  },
  computed: {
    hasFiles: function () {
      return this.files.length > 0
    }
  },
  methods: {
    formatNodePath: function (nodePath) {
      return formatPath(nodePath)
    }
  }
}
</script>

<style scoped>
.upload-file-details {
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.upload-file-details__header {
  min-height: 40px;
  padding: 8px 12px;
  width: 100%;
}

.upload-file-details__title {
  font-size: 14px;
  font-weight: 500;
}

.upload-file-details__icon {
  color: rgba(0, 0, 0, 0.54);
}

/* Override Vuetify's default expansion panel header styles */
.upload-file-details ::v-deep .v-expansion-panel__header {
  min-height: 40px;
  padding: 0;
}

.upload-file-details ::v-deep .v-expansion-panel__header__icon {
  margin-left: 8px;
}

.upload-file-details ::v-deep .v-expansion-panel__header:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.upload-file-details__list {
  height: 300px;
  overflow-y: auto;
}

/* RecycleScroller specific styles */
.upload-file-details__list ::v-deep .vue-recycle-scroller__item-wrapper {
  width: 100%;
}

.upload-file-details__list ::v-deep .vue-recycle-scroller__item-view {
  width: 100%;
}
</style> 